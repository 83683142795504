var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-neutral-6-transparent",class:{
		'step-highlight': _vm.demo && _vm.follow_tutorial && _vm.get_step('run', 'targeted'),
	},attrs:{"id":"targeted"},on:{"focus":function($event){return _vm.$emit('focus')}}},[_c('h2',{staticClass:"componentHeader",class:{ shadow: _vm.setShadow > 0 }},[_c('div',{staticClass:"d-flex justify-content-between"},[_vm._m(0),(_vm.targeted.length > 0)?_c('button',{staticClass:"btn btn-clear",on:{"click":function($event){return _vm.set_targeted({ type: 'untarget', key: 'all' })}}},[_c('i',{staticClass:"fas fa-times red",attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v("Untarget all")])],1):_vm._e()]),(_vm.targeted.length)?_c('div',{staticClass:"options d-flex justify-content-between gap-1",attrs:{"tabindex":"0"},on:{"focus":_vm.focusOptions}},_vm._l((_vm.display_options),function({ key, method, icon, tooltip, step },i){return _c('button',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(key),expression:"key"}],key:`option-${i}`,ref:"options",refInFor:true,staticClass:"option",class:{
					'step-highlight': step && _vm.demo && _vm.follow_tutorial && _vm.get_step('run', step),
				},attrs:{"tabindex":"-1"},on:{"click":method,"shortkey":method,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;return _vm.cycleOptions(i, 'left')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;return _vm.cycleOptions(i, 'right')}]}},[_c('i',{staticClass:"fas",class:icon,attrs:{"aria-hidden":"true"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(_vm._s(tooltip))]),(_vm.demo && step)?_c('TutorialPopover',{attrs:{"tutorial":"run","step":step,"position":"bottom","offset":[0, 10]}}):_vm._e()],1)}),0):_vm._e()]),_c('q-scroll-area',{ref:"scroll",attrs:{"dark":_vm.$store.getters.theme === 'dark',"thumb-style":{ width: '5px' }},on:{"scroll":function($event){return _vm.shadow()}}},[_c('div',{staticClass:"current"},[(_vm.targeted.length === 1)?[_c('TargetInfo',{attrs:{"data":{ key: _vm.targeted[0] }}})]:(_vm.targeted.length > 1)?_vm._l((_vm.targeted),function(key){return _c('div',{key:`target-${key}`,staticClass:"target"},[_c('div',{staticClass:"health"},[_c('TargetItem',{attrs:{"item":key}}),_c('a',{staticClass:"clear",on:{"click":function($event){return _vm.set_targeted({ type: 'untarget', key })}}},[_c('hk-icon',{attrs:{"icon":"fas fa-times red"}}),_c('q-tooltip',{attrs:{"anchor":"top middle","self":"center middle"}},[_vm._v(" Untarget ")])],1)],1),_c('div',{staticClass:"scores"},[_vm._l((_vm.abilities),function(ability,index){return [(_vm.entities[key][ability])?_c('div',{key:`score-${index}`,staticClass:"ability"},[_c('hk-roll',{attrs:{"tooltip":"Roll check","roll":{
										d: 20,
										n: 1,
										m: _vm.modifier(_vm.entities[key][ability]),
										title: `${ability.capitalize()} check`,
										entity_name: _vm.entities[key].name.capitalizeEach(),
										notify: true,
									},"share":_vm.shares.includes('ability_rolls')
											? {
													encounter_id: _vm.encounterId,
													entity_key: key,
												}
											: null}},[_c('div',{staticClass:"abilityName"},[_vm._v(_vm._s(ability.substring(0, 3).toUpperCase()))]),_c('div',{staticClass:"mod bg-neutral-8"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.modifier(_vm.entities[key][ability]) > 0
												? `+${_vm.modifier(_vm.entities[key][ability])}`
												: _vm.modifier(_vm.entities[key][ability]))+"\n\t\t\t\t\t\t\t\t\t")])]),_c('hk-roll',{attrs:{"tooltip":"Roll save","roll":{
										d: 20,
										n: 1,
										m: _vm.savingThrow(_vm.entities[key], ability),
										title: `${ability.capitalize()} save`,
										entity_name: _vm.entities[key].name.capitalizeEach(),
										notify: true,
									},"share":_vm.shares.includes('save_rolls')
											? {
													encounter_id: _vm.encounterId,
													entity_key: key,
												}
											: null}},[_c('div',{staticClass:"mod bg-neutral-8"},[_vm._v("\n\t\t\t\t\t\t\t\t\t\t"+_vm._s(_vm.savingThrow(_vm.entities[key], ability))+"\n\t\t\t\t\t\t\t\t\t")])])],1):_vm._e()]})],2)])}):_c('div',{staticClass:"noTargetInfo"},[_c('h3',{staticClass:"red"},[_vm._v("No target selected")]),_c('p',[_vm._v("Select at least 1 target from the target list to perform targeted actions.")]),_c('p',[_c('strong',[_vm._v("Selecting a target")]),_c('br'),_vm._v("Click on an entity in the target list, or use\n\t\t\t\t\t[0-9] on your keyboard to target it.\n\t\t\t\t")]),_c('p',[_c('strong',[_vm._v("Multi-targeting")]),_c('br'),_vm._v("Hold down shift and click on multiple entities to\n\t\t\t\t\ttarget them all at once.\n\t\t\t\t")]),_c('p',[_c('strong',[_vm._v("Cycle through targets")]),_c('br'),_vm._v("Use the up and down arrow keys on your\n\t\t\t\t\tkeyboard to cycle through the targets. Hold shift to select multiple targets in a row.\n\t\t\t\t")])])],2)]),(_vm.demo)?_c('TutorialPopover',{attrs:{"tutorial":"run","position":"right","step":"targeted","offset":[10, 0]}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"fas fa-crosshairs",attrs:{"aria-hidden":"true"}}),_vm._v(" Targeted")])
}]

export { render, staticRenderFns }