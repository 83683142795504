import { render, staticRenderFns } from "./Turns.vue?vue&type=template&id=ed3a5070&scoped=true"
import script from "./Turns.vue?vue&type=script&lang=js"
export * from "./Turns.vue?vue&type=script&lang=js"
import style0 from "./Turns.vue?vue&type=style&index=0&id=ed3a5070&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed3a5070",
  null
  
)

export default component.exports
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPopupProxy,QList,QItem,QItemSection,QSeparator,QIcon,QTooltip});qInstall(component, 'directives', {ClosePopup});
